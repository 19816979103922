import React from 'react';
import OrgChart from './components/OrgChart.js';
import Me from './components/Me.js';
import './App.css'

const App = () => {
  return (
    <div>
      <OrgChart />
      <Me />
    </div>
  );
};
export default App;