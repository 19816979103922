import React, { useState, useEffect } from 'react';
import ReactOrgChart from '@unicef/react-org-chart';

const OrgChart = () => {
  const [orgData, setOrgData] = useState(null);

  const fetchOrgData = () => {
    fetch(`${process.env.API_URL}/organization-chart`)
      .then((response) => response.json())
      .then((data) => {
        setOrgData(data);
      })
      .catch((err) => {
        console.log('error fetching org data', err);
      })
  }

  useEffect(() => {
    fetchOrgData();
  }, []);

  return (
    <div>
      <h1>Organization Chart</h1>
      <button onClick={fetchOrgData}>Fetch Organization Data</button>
      {orgData ? (
        <ReactOrgChart tree={orgData} />
      ) : (
        <p>Loading organization chart.</p>
      )}
    </div>
  );
};

export default OrgChart;
