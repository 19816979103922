import React, { useState, useEffect } from 'react';

const Me = () => {
  const [meData, setMeData] = useState(null);

  const fetchMeData = () => {
    fetch(`${process.env.API_URL}/me`)
      .then((response) => response.json())
      .then((data) => {
        setMeData(data);
      })
      .catch((err) => { console.log("error fetching /me", err); })
  };

  useEffect(() => {
    fetchMeData();
  }, []);

  return (
    <div>
      <h1>My Info!</h1>
      <button onClick={fetchMeData}>Fetch My Information</button>
      {meData ? (
        <div>
          <p>Name: {meData.name}</p>
          <p>Homepage: {meData.homepage}</p>
          <p>Github URL: {meData.githubURL}</p>
          <p>Interesting Fact: {meData.interestingFact}</p>
          <p>Skills: {meData.skills.join(', ')}</p>
        </div>
      ) : (
        <p>Loading my info.</p>
      )}
    </div>
  );
};

export default Me;
